import { getState, saveState } from '@/utils/helpers';
import api from '@/utils/api';

export const state = {
	newNotifications: getState('notifications') || [],
	count: 0,
};

export const getters = {
	projectNotifications(state) {
		return (projectId) => state.notifications.filter((n) => n.project_id === projectId);
	},
};

export const mutations = {
	SET_NOTIFICATIONS(state, data) {
		state.newNotifications = data?.data ?? [];
		state.count = data?.meta?.total ?? 0;
		saveState('notifications', data.data);
	},
};

export const actions = {
	async init({ commit, rootState }) {
		if (!rootState.auth.user) return;

		const { data } = await api.call({ path: 'notifications/unread', method: 'GET', noLoading: true });
		commit('SET_NOTIFICATIONS', data);

		return data.data;
	},

	async getNotifications(_, params) {
		let path = `notifications?perPage=${params.perPage}&page=${params.currentPage}`;
		path += params.project ? `&project=${params.project}` : '';
		path += params.date ? `&date=${params.date}` : '';
		path += params.type ? `&type=${params.type}` : '';

		const { data } = await api.call({ path, method: 'GET', noLoading: true });
		return data;
	},

	async readNotifications({ commit }) {
		await api.call({ path: 'notifications/read', method: 'PUT', noLoading: true });
		commit('SET_NOTIFICATIONS', {
			data: [],
			meta: {
				total: 0,
			},
		});
	},

	async deleteNotifications(_, id) {
		await api.call({ path: `notifications/${id}`, method: 'DELETE' });
	},
};
