import api from '@/utils/api';
import { buildQueryString } from '@/utils/helpers';

export const state = {
	users: [],
};

export const getters = {};

export const mutations = {
	SET_USERS(state, userList) {
		state.users = userList;
	},
};

export const actions = {
	async getUsers({ commit }, params) {
		let path = params ? `users?${buildQueryString(params)}` : 'users';
		if (params.type && params.currentPage) {
			path += `&type=${params.type}`;
		}

		const { data } = await api.call({
			path,
			method: 'GET',
			noLoading: true,
		});

		commit('SET_USERS', data.data);

		return data;
	},

	async addUser(_, data) {
		await api.call({ path: '/users', data });
	},

	async updateUser(_, data) {
		await api.call({ path: `/users/${data.userId}`, data, method: 'PUT' });
	},

	async getPermissions() {
		const { data } = await api.call({ path: 'users/permissions' });
		return data.data;
	},

	async getUserPermissions(_, userId) {
		const { data } = await api.call({ path: `users/permissions/user/${userId}` });
		return data.data;
	},

	async savePermissions(_, { data, userId }) {
		await api.call({ path: `users/permissions/user/${userId}/sync`, data });
		return data;
	},

	async syncUsers() {
		const { data } = await api.call({ path: 'users/sync' });
		return data.data;
	},
};
