export default [
	{
		path: 'users',
		name: 'users',
		component: () => import('@/views/settings/users/Index.vue'),
		meta: {
			pageTitle: 'Users',
			breadcrumb: [{ text: 'Users', active: true }],
			authRequired: true,
		},
	},

	{
		path: 'consultants',
		name: 'consultants',
		component: () => import('@/views/settings/consultants/Index.vue'),
		meta: {
			pageTitle: 'Consultants',
			breadcrumb: [{ text: 'Consultants', active: true }],
			authRequired: true,
		},
	},

	{
		path: 'accountants',
		name: 'accountants',
		component: () => import('@/views/settings/accountants/Index.vue'),
		meta: {
			pageTitle: 'Consultants',
			breadcrumb: [{ text: 'Consultants', active: true }],
			authRequired: true,
		},
	},

	{
		path: 'technicians',
		name: 'technicians',
		component: () => import('@/views/settings/technicians/Index.vue'),
		meta: {
			pageTitle: 'Technicians',
			breadcrumb: [{ text: 'Technicians', active: true }],
			authRequired: true,
		},
	},
];
