export default [
	// Number of accidents and man work Hours.
	{
		path: 'accidents-and-work-hours',
		name: 'reports.accidents-and-work-hours',
		component: () => import('@/views/reports/accidents-and-work-hours/Index.vue'),
		meta: {
			pageTitle: 'Number of accidents',
			breadcrumb: [{ text: 'Number of accidents', active: true }],
			authRequired: true,
		},
	},
	// location visits
	{
		path: 'location-visits',
		name: 'reports.location-visits',
		component: () => import('@/views/reports/location-visits/Index.vue'),
		meta: {
			pageTitle: 'Location visits',
			breadcrumb: [{ text: 'Location visits', active: true }],
			authRequired: true,
		},
	},
	// ESMPs & Safeguard esmps-indicators
	{
		path: 'esmps-indicators',
		name: 'reports.esmps-indicators',
		component: () => import('@/views/reports/esmps-indicators/Index.vue'),
		meta: {
			pageTitle: 'ESMPs indicators',
			breadcrumb: [{ text: 'ESMPs indicators', active: true }],
			authRequired: true,
		},
	},

	// ESMPs & Safeguard photos
	// {
	// 	path: 'esmps-photos',
	// 	name: 'reports.esmps-photos',
	// 	component: () => import('@/views/reports/esmps-photos/Index.vue'),
	// 	meta: {
	// 		pageTitle: 'Reports',
	// 		breadcrumb: [{ text: 'esmps-photos', active: true }],
	// 		authRequired: true,
	// 	},
	// },

	// GRM
	{
		path: 'grm',
		name: 'reports.grm',
		component: () => import('@/views/reports/grm/Index.vue'),
		meta: {
			pageTitle: 'grm',
			breadcrumb: [{ text: 'grm', active: true }],
			authRequired: true,
		},
	},

	// Main weakness / Gaps and Corrective Actions - details
	{
		path: 'main-weakness-corrective-actions-details',
		name: 'reports.main-weakness-corrective-actions-details',
		component: () => import('@/views/reports/main-weakness-corrective-actions-details/Index.vue'),
		meta: {
			pageTitle: 'Reports',
			breadcrumb: [{ text: 'Main weakness corrective actions details', active: true }],
			authRequired: true,
		},
	},

	// Main weakness / Gaps and Corrective Actions - photos
	// {
	// 	path: 'main-weakness-corrective-actions-photos',
	// 	name: 'reports.main-weakness-corrective-actions-photos',
	// 	component: () => import('@/views/reports/main-weakness-corrective-actions-photos/Index.vue'),
	// 	meta: {
	// 		pageTitle: 'Reports',
	// 		breadcrumb: [{ text: 'Main weakness corrective actions photos', active: true }],
	// 		authRequired: true,
	// 	},
	// },

	// Training and raise awareness details
	{
		path: 'training-raise-awareness-details',
		name: 'reports.training-raise-awareness-details',
		component: () => import('@/views/reports/training-raise-awareness-details/Index.vue'),
		meta: {
			pageTitle: 'Reports',
			breadcrumb: [{ text: 'Training and raise awareness details', active: true }],
			authRequired: true,
		},
	},

	// Training and raise awareness photos
	// {
	// 	path: 'training-raise-awareness-photos',
	// 	name: 'reports.training-raise-awareness-photos',
	// 	component: () => import('@/views/reports/training-raise-awareness-photos/Index.vue'),
	// 	meta: {
	// 		pageTitle: 'Reports',
	// 		breadcrumb: [{ text: 'Training and raise awareness photos', active: true }],
	// 		authRequired: true,
	// 	},
	// },
];
