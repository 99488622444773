export const state = {
	chats: [
		{
			chat: {
				id: 2,
				userId: 1,
				unseenMsgs: 0,
				chat: [
					{ message: 'How can we help? We are here for you!', time: 'Mon Dec 10 2018 07:45:10 GMT+0000 (GMT)', senderId: 11 },
					{
						message: 'Hey John, I am looking for the best admin template. Could you please help me to find it out?',
						time: 'Mon Dec 10 2018 07:45:23 GMT+0000 (GMT)',
						senderId: 1,
					},
					{ message: 'It should be Bootstrap 4 compatible.', time: 'Mon Dec 10 2018 07:45:55 GMT+0000 (GMT)', senderId: 1 },
					{ message: 'Absolutely!', time: 'Mon Dec 10 2018 07:46:00 GMT+0000 (GMT)', senderId: 11 },
					{
						message: 'Modern admin is the responsive bootstrap 4 admin template.!',
						time: 'Mon Dec 10 2018 07:46:05 GMT+0000 (GMT)',
						senderId: 11,
					},
					{ message: 'Looks clean and fresh UI.', time: 'Mon Dec 10 2018 07:46:23 GMT+0000 (GMT)', senderId: 1 },
					{ message: 'It is perfect for my next project.', time: 'Mon Dec 10 2018 07:46:33 GMT+0000 (GMT)', senderId: 1 },
					{ message: 'How can I purchase it?', time: 'Mon Dec 10 2018 07:46:43 GMT+0000 (GMT)', senderId: 1 },
					{ message: 'Thanks, from ThemeForest.', time: 'Mon Dec 10 2018 07:46:53 GMT+0000 (GMT)', senderId: 11 },
					{ message: 'I will purchase it for sure. 👍', time: '2021-06-09T06:44:08.245Z', senderId: 1 },
				],
			},
			contact: {
				id: 1,
				fullName: 'Felecia Rower',
				role: 'Frontend Developer',
				about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
				avatar: '/img/1.9cba4a79.png',
				status: 'offline',
				chat: {
					id: 2,
					unseenMsgs: 1,
					lastMessage: { message: 'I will purchase it for sure. 👍', time: '2021-06-09T06:44:08.245Z', senderId: 1 },
				},
			},
		},
		{
			chat: {
				id: 1,
				userId: 2,
				unseenMsgs: 0,
				chat: [
					{ message: 'Hi', time: 'Mon Dec 10 2018 07:45:00 GMT+0000 (GMT)', senderId: 11 },
					{ message: 'Hi', time: 'Mon Dec 10 2018 06:25:00 GMT+0000 (GMT)', senderId: 11 },
					{ message: 'Hi', time: 'Mon Dec 10 2018 01:45:00 GMT+0000 (GMT)', senderId: 11 },
					{ message: 'Hello. How can I help You?', time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)', senderId: 2 },
					{
						message: 'Can I get details of my last transaction I made last month?',
						time: 'Mon Dec 11 2018 07:46:10 GMT+0000 (GMT)',
						senderId: 11,
					},
					{
						message: 'We need to check if we can provide you such information.',
						time: 'Mon Dec 11 2018 07:45:15 GMT+0000 (GMT)',
						senderId: 2,
					},
					{ message: 'I will inform you as I get update on this.', time: 'Mon Dec 11 2018 03:46:15 GMT+0000 (GMT)', senderId: 2 },
					{ message: 'I will inform you as I get update on this.', time: 'Mon Dec 11 2018 02:46:05 GMT+0000 (GMT)', senderId: 2 },
					{ message: 'I will inform you as I get update on this.', time: 'Mon Dec 11 2018 07:46:15 GMT+0000 (GMT)', senderId: 2 },
					{ message: 'If it takes long you can mail me at my mail address.', time: '2021-06-08T06:44:08.245Z', senderId: 11 },
				],
			},
			contact: {
				id: 2,
				fullName: 'Adalberto Granzin',
				role: 'UI/UX Designer',
				about:
					'Toffee caramels jelly-o tart bears cake I ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
				avatar: '/img/2.748e7504.png',
				status: 'busy',
				chat: {
					id: 1,
					unseenMsgs: 0,
					lastMessage: { message: 'If it takes long you can mail me at my mail address.', time: '2021-06-08T06:44:08.245Z', senderId: 11 },
				},
			},
		},
	],

	chatsContacts: [
		{
			id: 1,
			fullName: 'Felecia Rower',
			role: 'Frontend Developer',
			about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
			avatar: '/img/1.9cba4a79.png',
			status: 'offline',
			chat: {
				id: 2,
				unseenMsgs: 1,
				lastMessage: { message: 'I will purchase it for sure. 👍', time: '2021-06-08T11:22:05.241Z', senderId: 1 },
			},
		},
		{
			id: 2,
			fullName: 'Adalberto Granzin',
			role: 'UI/UX Designer',
			about:
				'Toffee caramels jelly-o tart cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
			avatar: '/img/2.748e7504.png',
			status: 'busy',
			chat: {
				id: 1,
				unseenMsgs: 0,
				lastMessage: {
					message: 'If it takes long you can mail me at my mail address.',
					time: '2021-06-07T11:22:05.241Z',
					senderId: 11,
				},
			},
		},
	],

	contacts: [
		{
			id: 1,
			fullName: 'Felecia Rower',
			role: 'Frontend Developer',
			about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
			avatar: '/img/1.9cba4a79.png',
			status: 'offline',
			chat: {
				id: 2,
				unseenMsgs: 1,
				lastMessage: { message: 'I will purchase it for sure. 👍', time: '2021-06-08T11:22:05.241Z', senderId: 1 },
			},
		},
		{
			id: 2,
			fullName: 'Adalberto Granzin',
			role: 'UI/UX Designer',
			about:
				'Toffee caramels jelly-o tart cake I love ice cream lollipop. Sweet liquorice croissant candy danish dessert icing. Cake macaroon gingerbread toffee sweet.',
			avatar: '/img/2.748e7504.png',
			status: 'busy',
			chat: {
				id: 1,
				unseenMsgs: 0,
				lastMessage: {
					message: 'If it takes long you can mail me at my mail address.',
					time: '2021-06-07T11:22:05.241Z',
					senderId: 11,
				},
			},
		},
		{
			id: 3,
			fullName: 'Joaquina Weisenborn',
			role: 'Town planner',
			about: 'Soufflé soufflé caramels sweet roll. Jelly lollipop sesame snaps bear claw jelly beans sugar plum sugar plum.',
			avatar: '/img/3.5df49333.png',
			status: 'busy',
		},
		{
			id: 4,
			fullName: 'Verla Morgano',
			role: 'Data scientist',
			about:
				'Chupa chups candy canes chocolate bar marshmallow liquorice muffin. Lemon drops oat cake tart liquorice tart cookie. Jelly-o cookie tootsie roll halvah.',
			avatar: '/img/4.7e5368dc.png',
			status: 'online',
		},
		{
			id: 5,
			fullName: 'Margot Henschke',
			role: 'Dietitian',
			about: 'Cake pie jelly jelly beans. Marzipan lemon drops halvah cake. Pudding cookie lemon drops icing',
			avatar: '/img/5.f13458cc.png',
			status: 'busy',
		},
	],

	profileUser: { id: 11, avatar: '/img/8.4ac55be3.png', fullName: 'John Doe', status: 'online' },
};

export const getters = {};

export const mutations = {};

export const actions = {
	fetchChatsAndContacts({ state }) {
		return new Promise((resolve) => {
			resolve({
				...state,
			});
		});
	},
	getProfileUser({ state }) {
		return new Promise((resolve) => {
			resolve(state.profileUser);
		});
	},
	getChat({ state }, { userId }) {
		const c = state.chats.find((chat) => chat.contact.id === userId);
		return new Promise((resolve) => {
			resolve(c);
		});
	},
	// sendMessage(ctx, { contactId, message, senderId }) {
	// 	return new Promise((resolve, reject) => {
	// 		// axios
	// 		// 	.post(`/apps/chat/chats/${contactId}`, { message, senderId })
	// 		// 	.then((response) => resolve(response))
	// 		// 	.catch((error) => reject(error));
	// 	});
	// },
};
