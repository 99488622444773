import api from '@/utils/api';
import { buildQueryString } from '@/utils/helpers';

export const state = {
	workers: [],
	workersReports: [],
	workersPayingReports: [],
	itemsReports: [],
	submissions: []
};

export const getters = {};

export const mutations = {
	SET_WORKERS(state, workers) {
		state.workers = workers;
	},

	SET_WORKERS_REPORTS(state, reports) {
		state.workersReports = reports;
	},

	SET_WORKERS_PAYING_REPORTS(state, reports) {
		state.workersPayingReports = reports;
	},

	SET_ITEMS_REPORTS(state, reports) {
		state.itemsReports = reports;
	},

	SET_SUBMISSIONS(state, submissions) {
		state.submissions = submissions;
	}
};

export const actions = {
	async getWorkers({ commit }, params) {
		const path = params ? `/migration/workers?${buildQueryString(params)}` : '/migration/workers';

		const { data } = await api.call({ path, method: 'GET', noLoading: true });
		commit('SET_WORKERS', data.data);

		return data;
	},

	async getWorkersReports({ commit }, params) {
		const path = params ? `/migration/workers-reports?${buildQueryString(params)}` : '/migration/workers-reports';

		const { data } = await api.call({ path, method: 'GET', noLoading: true });
		commit('SET_WORKERS_REPORTS', data.data);

		return data;
	},

	async getWorkersPayingReports({ commit }, params) {
		const path = params ? `/migration/workers-paying-reports?${buildQueryString(params)}` : '/migration/workers-paying-reports';

		const { data } = await api.call({ path, method: 'GET', noLoading: true });
		commit('SET_WORKERS_PAYING_REPORTS', data.data);

		return data;
	},

	async getItemsReports({ commit }, params) {
		const path = params ? `/migration/items-reports?${buildQueryString(params)}` : '/migration/items-reports';

		const { data } = await api.call({ path, method: 'GET', noLoading: true });
		commit('SET_ITEMS_REPORTS', data.data);

		return data;
	},

	async getSubmissions({ commit }, params) {
		const path = params ? `/migration/submissions?${buildQueryString(params)}` : '/migration/submissions';

		const { data } = await api.call({ path, method: 'GET', noLoading: true });
		commit('SET_SUBMISSIONS', data.data);

		return data;
	},

	async migrateSubmission(_, id) {
		const { data } = await api.call({ path: `migration/submissions/${id}/migrate`, method: 'PUT' });
		return data;
	},

	async migrateWorker(_, id) {
		const { data } = await api.call({ path: `migration/workers/${id}/migrate`, method: 'PUT' });
		return data;
	},

	async migrateWorkerReport(_, id) {
		const { data } = await api.call({ path: `migration/workers-reports/${id}/migrate`, method: 'PUT' });
		return data;
	},

	async migrateWorkerPayingReport(_, id) {
		const { data } = await api.call({ path: `migration/workers-paying-reports/${id}/migrate`, method: 'PUT' });
		return data;
	},

	async migrateItemReport(_, id) {
		const { data } = await api.call({ path: `migration/items-reports/${id}/migrate`, method: 'PUT' });
		return data;
	},

	async rollbackSubmission(_, { id, reason }) {
		const { data } = await api.call({ path: `migration/submissions/${id}/rollback`, method: 'PUT', data: { reason } });
		return data;
	},

	async rollbackWorker(_, { id, reason }) {
		const { data } = await api.call({ path: `migration/workers/${id}/rollback`, method: 'PUT', data: { reason } });
		return data;
	},

	async rollbackWorkerReport(_, { id, reason }) {
		const { data } = await api.call({ path: `migration/workers-reports/${id}/rollback`, method: 'PUT', data: { reason } });
		return data;
	},

	async rollbackWorkerPayingReport(_, { id, reason }) {
		const { data } = await api.call({ path: `migration/workers-paying-reports/${id}/rollback`, method: 'PUT', data: { reason } });
		return data;
	},

	async rollbackItemReport(_, { id, reason }) {
		const { data } = await api.call({ path: `migration/items-reports/${id}/rollback`, method: 'PUT', data: { reason } });
		return data;
	}
};
