/* eslint-disable no-shadow */

import api from '@/utils/api';

export const state = {
	items: [],
	itemReports: []
};

export const getters = {
	projectItems: (state) => (projectId) => state.items.filter((item) => item.projectId === parseInt(projectId, 10)),

	itemReports: (state) => (id) => state.itemReports.filter((r) => r.item_id === parseInt(id, 10))
};

export const mutations = {
	ADD_ITEM_REPORT(state, newReport) {
		state.itemReports.push(newReport);
	},

	SET_REPORTS(state, reports) {
		state.itemReports = reports;
	}
};

export const actions = {
	async getItemsReports({ commit }, id) {
		const { data } = await api.call({ path: `items/daily-report/${id}`, method: 'GET' });
		commit('SET_REPORTS', data.data);

		return data.data;
	},

	async getReports(_, { id, date }) {
		const query = date ? `?date=${date}` : '';
		const { data } = await api.call({ path: `items/daily-report/${id}${query}`, method: 'GET', noLoading: true });

		return data.data;
	},

	addReport({ commit }, newReport) {
		commit('ADD_ITEM_REPORT', newReport);
	}
};
