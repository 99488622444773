import { getState, saveState } from '@/utils/helpers';

export const state = {
	form: getState('attributes') || null,
};

export const getters = {
	getFillers(state) {
		return (state.form?.fillers || []).map((item) => ({ id: item.id, label: item.name }));
	},

	getPeriods(state) {
		return (state.form?.periods || []).map((item) => ({ id: item.id, label: item.name }));
	},

	getStages(state) {
		return (state.form?.stages || []).map((item) => ({ id: item.id, label: item.name }));
	},

	getTypes(state) {
		return (state.form?.types || []).map((item) => ({ id: item.id, label: item.name }));
	},

	getSectors(state) {
		const sectors = state.form?.sectors || [];
		const mainSectorsIds = Array.from(new Set(sectors.map((s) => s.sector_id)));
		const mainSectors = [];

		mainSectorsIds.forEach((id) => {
			mainSectors.push(sectors.find((s) => s.sector_id === id));
		});

		return mainSectors.map((item) => ({ id: item.sector_id, label: item.sector }));
	},

	getDonors(state) {
		return (state.form?.donors || []).map((item) => ({ id: item, label: item }));
	},

	getCities(state) {
		return (state.form?.cities || []).map((item) => ({ id: item, label: item }));
	},

	getSubSectors(state) {
		const sectors = state.form?.sectors || [];

		return (sectorId) => {
			const subSectors = sectors.filter((s) => s.sector_id === sectorId);
			return subSectors.map((item) => ({ id: item.sub_sector_id, label: item.sub_sector }));
		};
	},
};

export const mutations = {
	SET_ATTRIBUTES(state, data) {
		state.form = data.form;
		saveState('attributes', data.form);
	},
};
