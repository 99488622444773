import Vue from 'vue';
import api from '@/utils/api';
import { buildQueryString } from '@/utils/helpers';

export const state = {
	complaints: []
};

export const getters = {};

export const mutations = {
	SET_COMPLAINTS(state, complaints) {
		state.complaints = complaints;
	},

	ADD_COMPLAINT(state, complaint) {
		state.complaints.push(complaint);
	},

	EDIT_COMPLAINT(state, complaint) {
		state.complaints.forEach((c, i) => {
			if (c.id === complaint.id) {
				Vue.set(state.complaints, i, complaint);
			}
		});
	}
};

export const actions = {
	async getComplaints({ commit }, params) {
		const path = params ? `grm/complaints?${buildQueryString(params)}` : 'grm/complaints';

		const { data } = await api.call({
			path,
			method: 'GET',
			noLoading: true
		});

		commit('SET_COMPLAINTS', data.data);

		return data;
	},

	async attachedCategory({ commit }, complaint) {
		const { data } = await api.call({
			path: `grm/complaints/${complaint.id}/category/add`,
			data: { category: complaint.category, sub_category: complaint.sub_category }
		});

		commit('EDIT_COMPLAINT', data.data);

		return data;
	},

	async process({ commit }, data) {
		const res = await api.call({
			path: `grm/complaints/${data.id}/responses/store`,
			data
		});

		commit('EDIT_COMPLAINT', res.data.data);

		return res.data.data;
	},

	async transfer({ commit }, data) {
		const res = await api.call({
			path: `grm/complaints/${data.id}/transfer`,
			data
		});

		commit('EDIT_COMPLAINT', res.data.data);

		return res.data.data;
	},

	async close({ commit }, data) {
		const res = await api.call({
			path: `grm/complaints/${data.id}/close`,
			data
		});

		commit('EDIT_COMPLAINT', res.data.data);

		return res.data.data;
	},

	async stats() {
		const { data } = await api.call({
			path: 'grm/stats',
			method: 'GET'
		});

		return data;
	},

	async addComplaint({ commit }, complaint) {
		const { data } = await api.call({ path: 'grm/complaints/create', data: complaint });
		commit('ADD_COMPLAINT', data.data);

		return data.data;
	},

	async getComplaint({ state, dispatch }, complaintId) {
		if (!state.complaints.length) await dispatch('getComplaints');
		return state.complaints.find((c) => c.id === complaintId);
	}
};
