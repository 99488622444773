import api from '@/utils/api';
import Vue from 'vue';

export const state = {
	categories: [],
};

export const getters = {};

export const mutations = {
	SET_CATEGORIES(state, categories) {
		state.categories = categories;
	},

	ADD_CATEGORY(state, category) {
		state.categories.push(category);
	},

	EDIT_CATEGORY(state, { id, data }) {
		state.categories.forEach((category, i) => {
			if (category.id === id) {
				Vue.set(state.categories, i, data);
			}
		});
	},
};

export const actions = {
	async getCategories({ commit, state }) {
		if (state.categories.length) return state.categories;

		const { data } = await api.call({ path: 'grm/categories' });
		commit('SET_CATEGORIES', data.data);

		return data.data;
	},

	async addCategory({ commit }, catData) {
		const { data } = await api.call({ path: 'grm/categories/store', data: catData });
		commit('ADD_CATEGORY', data.data);

		return data.data;
	},

	async editCategory({ commit }, { id, catData }) {
		const { data } = await api.call({ path: `grm/categories/${id}/update`, data: catData });
		commit('EDIT_CATEGORY', { id, data: data.data });

		return data.data;
	},
};
