import api from '@/utils/api';

export const state = {
	plans: [],
	orders: [],
	acceptedOrders: [],
	invoices: [],
};

export const getters = {};

export const mutations = {
	SET_PLANS(state, plans) {
		state.plans = plans;
	},
	SET_ORDERS(state, orders) {
		state.orders = orders;
	},
	SET_ACCEPTED_ORDERS(state, acceptedOrders) {
		state.acceptedOrders = acceptedOrders;
	},
	SET_INVOICES(state, invoices) {
		state.invoices = invoices;
	},
};

export const actions = {
	async getPlans({ commit }, projectId) {
		const { data } = await api.call({ path: `purchases/${projectId}/plans`, method: 'GET', noLoading: true });
		commit('SET_PLANS', data);

		return data;
	},
	async getOrders({ commit }, projectId) {
		const { data } = await api.call({ path: `purchases/${projectId}/orders`, method: 'GET' });
		commit('SET_ORDERS', data);

		return data;
	},
	async getAcceptedOrders({ commit }, projectId) {
		const { data } = await api.call({ path: `purchases/${projectId}/accepted-orders`, method: 'GET' });
		commit('SET_ACCEPTED_ORDERS', data.data);

		return data.data;
	},
	async getInvoices({ commit }, projectId) {
		const { data } = await api.call({ path: `purchases/${projectId}/invoices`, method: 'GET' });
		commit('SET_INVOICES', data);

		return data;
	},
};
