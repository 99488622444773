export default [
	{
		path: '/projects/eep/:id/setup',
		name: 'project.cfw.setup',
		component: () => import('@/views/projects/cash-for-work/setup/Index.vue'),
		meta: {
			pageTitle: 'Setup',
			breadcrumb: [
				{
					to: '/projects',
					text: 'Projects',
					active: false,
				},
				{
					text: 'Setup',
					active: true,
				},
			],
			authRequired: true,
		},
		props: true,
	},
	{
		path: '/projects/eep/:id',
		name: 'project.cfw.details',
		component: () => import('@/views/projects/cash-for-work/Index.vue'),
		meta: {
			pageTitle: 'Projects',
			breadcrumb: [
				{
					to: '/projects',
					text: 'Projects',
					active: false,
				},
				{
					text: 'Details',
					active: true,
				},
			],
			authRequired: true,
		},
		props: true,
		children: [
			{
				path: '',
				name: 'project.cfw.details',
				component: () => import('@/views/projects/cash-for-work/sub-projects/Index.vue'),
				meta: {
					pageTitle: 'Sub Projects',
					breadcrumb: [
						{
							to: '/projects',
							text: 'Projects',
							active: false,
						},
						{
							text: 'Sub Projects',
							active: true,
						},
					],
					authRequired: true,
					navActiveLink: 'projects',
				},
				props: true,
			},
			{
				path: 'stores',
				name: 'project.cfw.stores',
				component: () => import('@/views/projects/cash-for-work/stores/Index.vue'),
				meta: {
					pageTitle: 'Stores',
					breadcrumb: [
						{
							to: '/projects',
							text: 'Projects',
							active: false,
						},
						{
							text: 'Stores',
							active: true,
						},
					],
					authRequired: true,
				},
				props: true,
			},
			{
				path: 'able-to-work',
				name: 'project.cfw.able-to-work',
				component: () => import('@/views/projects/cash-for-work/able-to-work/Index.vue'),
				meta: {
					pageTitle: 'Able To Work',
					breadcrumb: [
						{
							to: '/projects',
							text: 'Projects',
							active: false,
						},
						{
							text: 'Able To Work',
							active: true,
						},
					],
					authRequired: true,
				},
				props: true,
			},
			{
				path: 'staff',
				name: 'project.cfw.staff',
				component: () => import('@/views/projects/cash-for-work/employees/Index.vue'),
				meta: {
					pageTitle: 'Project staff',
					breadcrumb: [
						{
							to: '/projects',
							text: 'Projects',
							active: false,
						},
						{
							text: 'Project staff',
							active: true,
						},
					],
					authRequired: true,
				},
				props: true,
			},
			{
				path: 'plans',
				name: 'project.cfw.plans',
				component: () => import('@/views/projects/cash-for-work/plans/Index.vue'),
				meta: {
					pageTitle: 'Purchase Plans',
					breadcrumb: [
						{
							to: '/projects',
							text: 'Projects',
							active: false,
						},
						{
							text: 'Purchase Plans',
							active: true,
						},
					],
					authRequired: true,
				},
				props: true,
			},
			{
				path: 'reports',
				name: 'project.cfw.reports',
				component: () => import('@/views/projects/cash-for-work/reports/Index.vue'),
				meta: {
					pageTitle: 'Reports',
					breadcrumb: [
						{
							to: '/projects',
							text: 'Projects',
							active: false,
						},
						{
							text: 'Reports',
							active: true,
						},
					],
					authRequired: true,
				},
				props: true,
			},
		],
	},
	{
		path: '/projects/eep/:id/sub-projects/:subId',
		name: 'project.cfw.sub-project.details',
		component: () => import('@/views/projects/cash-for-work/details/Index.vue'),
		meta: {
			pageTitle: 'Projects',
			breadcrumb: [
				{
					to: '/projects',
					text: 'Projects',
					active: false,
				},
				{
					text: 'Details',
					active: true,
				},
			],
			authRequired: true,
			group: 'projects',
		},
		props: true,
		children: [
			{
				path: '',
				name: 'project.cfw.sub-project.details',
				component: () => import('@/views/projects/cash-for-work/details/purchases/Index.vue'),
				meta: {
					pageTitle: 'Purchases',
					breadcrumb: [
						{
							to: '/projects',
							text: 'Projects',
							active: false,
						},
						{
							text: 'Purchases',
							active: true,
						},
					],
					authRequired: true,
				},
			},
			{
				path: 'items',
				name: 'project.cfw.sub-project.items',
				component: () => import('@/views/projects/cash-for-work/items/sub-project-items.vue'),
				meta: {
					pageTitle: 'Sub Project Items',
					breadcrumb: [
						{
							to: '/projects',
							text: 'Projects',
							active: false,
						},
						{
							text: 'Sub Project Items',
							active: true,
						},
					],
					authRequired: true,
					group: 'projects',
				},
				props: true,
			},
			{
				path: 'workers',
				name: 'project.cfw.sub-project.workers',
				component: () => import('@/views/projects/cash-for-work/details/workers/Index.vue'),
				meta: {
					pageTitle: 'Workers',
					breadcrumb: [
						{
							to: '/projects',
							text: 'Projects',
							active: false,
						},
						{
							text: 'Workers',
							active: true,
						},
					],
					authRequired: true,
				},
			},
		],
	},
	{
		path: '/projects/eep/:id/sub-projects/:subId/new-payment',
		name: 'project.cfw.sub-project.new-payment',
		component: () => import('@/views/projects/cash-for-work/details/workers/payments/new-payment.vue'),
		meta: {
			pageTitle: 'Workers',
			breadcrumb: [
				{
					to: '/projects',
					text: 'Projects',
					active: false,
				},
				{
					text: 'Workers',
					active: true,
				},
			],
			authRequired: true,
		},
		props: true,
	},
];
