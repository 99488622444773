export default [
	{
		path: '',
		name: 'm&e',
		component: () => import('@/views/m&e/community-assists/DataTable.vue'),
		meta: {
			pageTitle: 'M&E',
			breadcrumb: [
				{
					text: 'M&E',
					to: '/m&e',
					active: false
				},
				{
					text: 'Community Assists',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'beneficiaries',
		name: 'm&e.beneficiaries',
		component: () => import('@/views/m&e/beneficiaries/Index.vue'),
		meta: {
			pageTitle: 'M&E',
			breadcrumb: [
				{
					text: 'M&E',
					to: '/m&e',
					active: false
				},
				{
					text: 'Beneficiaries',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'projects',
		name: 'm&e.projects',
		component: () => import('@/views/m&e/projects-status/DataTable.vue'),
		meta: {
			pageTitle: 'M&E',
			breadcrumb: [
				{
					text: 'M&E',
					to: '/m&e',
					active: false
				},
				{
					text: 'Project status',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'gender',
		name: 'm&e.gender',
		component: () => import('@/views/m&e/gender/Index.vue'),
		meta: {
			pageTitle: 'M&E',
			breadcrumb: [
				{
					text: 'M&E',
					to: '/m&e',
					active: false
				},
				{
					text: 'Gender',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'indicators',
		name: 'm&e.indicators',
		component: () => import('@/views/m&e/indicators/Index.vue'),
		meta: {
			pageTitle: 'M&E',
			breadcrumb: [
				{
					text: 'M&E',
					to: '/m&e',
					active: false
				},
				{
					text: 'Indicators',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'indicators/new',
		name: 'm&e.indicators.new',
		component: () => import('@/views/m&e/indicators/NewIndicator.vue'),
		meta: {
			pageTitle: 'M&E',
			breadcrumb: [
				{
					text: 'M&E',
					to: '/m&e',
					active: false
				},
				{
					text: 'Indicators',
					to: '/m&e/indicators',
					active: false
				},
				{
					text: 'New Indicator',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'indicators/edit/:id',
		name: 'm&e.indicators.edit',
		component: () => import('@/views/m&e/indicators/NewIndicator.vue'),
		meta: {
			pageTitle: 'M&E',
			breadcrumb: [
				{
					text: 'M&E',
					to: '/m&e',
					active: false
				},
				{
					text: 'Indicators',
					to: '/m&e/indicators',
					active: false
				},
				{
					text: 'New Indicator',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'maps',
		name: 'm&e.maps',
		component: () => import('@/views/m&e/maps/Index.vue'),
		meta: {
			pageTitle: 'M&E',
			breadcrumb: [
				{
					text: 'M&E',
					to: '/m&e',
					active: false
				},
				{
					text: 'Maps',
					active: true
				}
			],
			authRequired: true
		}
	}
];
