import api from '@/utils/api';

export const state = {
	items: [],
};

export const getters = {};

export const mutations = {
	SET_ITEMS(state, items) {
		state.items = items;
	},
};

export const actions = {
	async getItems({ commit }, projectId) {
		const { data } = await api.call({ path: `projects/${projectId}/store`, method: 'GET' });
		commit('SET_ITEMS', data);

		return data;
	},
};
