function generateId() {
	const rand = Math.random()
		.toString(36)
		.substr(2);

	return `_${Date.now().toString(36)}${rand}`;
}

function getOrder(list) {
	return list.length === 0 ? 1 : Math.max(...list.map((item) => item.order)) + 1;
}

function getDefaultPage() {
	return {
		id: generateId(),
		order: 1,
		name: 'First page',
		description: '',
		fields: [],
	};
}

function random(min, max) {
	const localMin = Math.ceil(min);
	const localMax = Math.floor(max);

	return Math.floor(Math.random() * (localMax - localMin + 1)) + localMin;
}

function getState(key) {
	if (!window.localStorage.getItem(key)) return null;

	return JSON.parse(window.localStorage.getItem(key));
}

function saveState(key, state) {
	window.localStorage.setItem(key, JSON.stringify(state));
}

function parseFileToBase64(file) {
	return new Promise((resolve, reject) => {
		const fileReader = new FileReader();
		fileReader.readAsDataURL(file);
		fileReader.onload = () => {
			// remove the 'data:image/jpeg;base64,' from the base64 text
			resolve(fileReader.result.slice(fileReader.result.indexOf('base64,') + 7));
		};
		fileReader.onerror = (error) => reject(error);
	});
}

function getGRMStatusColor(status) {
	if (status === 'New') return 'light-primary';
	if (status === 'Open') return 'light-info';
	if (status === 'Waiting response') return 'light-danger';
	if (status === 'Closed') return 'light-success';
}

function getProjectType(type) {
	if (type === 1) return 'General';
	if (type === 2) return 'Soclia Contracting';
	if (type === 3) return 'Emergency employment program';
}

function buildQueryString(params) {
	if (!params?.currentPage) return new URLSearchParams(params);

	const search = params.searchQuery ? `&q=${params.searchQuery}` : '';
	return `page=${params.currentPage}&perPage=${params.perPage}&sortBy=${params.sortBy}&sortDesc=${params.sortDesc}${search}`;
}

function getProjectLocation() {
	const project = getState('currentProject');
	if (!project || !project.location) return undefined;

	return [project.location.latitude, project.location.longitude];
}

function getProperDate(date) {
	const dateObj = new Date(date.date);
	const daysBetween = new Date().getDate() - dateObj.getDate();
	const theSameMonth = new Date().getMonth() === dateObj.getMonth();

	return daysBetween > 7 || !theSameMonth ? date.date : date.humans;
}

function translateBoolean(val) {
	return val ? 'Yes' : 'No';
}

function formatMoney(val, isDollar = false) {
	const formatter = Intl.NumberFormat(isDollar ? 'en-US' : 'ar-YE', {
		style: 'currency',
		currency: isDollar ? 'USD' : 'YER',
	});

	return formatter.format(val);
}

export {
	generateId,
	getOrder,
	getDefaultPage,
	random,
	getState,
	saveState,
	parseFileToBase64,
	getGRMStatusColor,
	buildQueryString,
	getProjectType,
	getProjectLocation,
	getProperDate,
	translateBoolean,
	formatMoney,
};
