/* eslint-disable global-require */
import Vue from 'vue';
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue';
import VueCompositionAPI from '@vue/composition-api';
import { Icon } from 'leaflet';

import router from './router';
import store from './store';
import VCan from './plugins/v-can';
import App from './App.vue';

// Global Components
import './global-components';
import '@/libs/portal-vue';

// 3rd party plugins
import '@/libs/toastification';

// eslint-disable-next-line no-underscore-dangle
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
	iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
	iconUrl: require('leaflet/dist/images/marker-icon.png'),
	shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

// BSV Plugin Registration
Vue.use(ToastPlugin);
Vue.use(ModalPlugin);
Vue.use(VCan);

// Composition API
Vue.use(VueCompositionAPI);

store.dispatch('auth/init');
store.dispatch('notifications/init');

// import core styles
require('@core/scss/core.scss');

// import assets styles
require('@/assets/scss/style.scss');

Vue.config.productionTip = false;

new Vue({
	router,
	store,
	render: (h) => h(App)
}).$mount('#app');
