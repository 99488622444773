import api from '@/utils/api';
import { buildQueryString } from '@/utils/helpers';
import Vue from 'vue';

export const state = {
	materials: [],
};

export const getters = {};

export const mutations = {
	SET_MATERIALS(state, materials) {
		state.materials = materials;
	},

	ADD_MATERIAL(state, material) {
		state.materials.push(material);
	},

	EDIT_MATERIAL(state, { id, data }) {
		state.materials.forEach((material, i) => {
			if (material.id === id) {
				Vue.set(state.materials, i, data);
			}
		});
	},

	DELETE_MATERIAL(state, id) {
		state.materials = state.materials.filter((m) => m.id !== id);
	},
};

export const actions = {
	async getMaterials(_, params) {
		const path = `materials?${buildQueryString(params)}`;

		const { data } = await api.call({
			path,
			method: 'GET',
			noLoading: true,
		});

		return data;
	},

	async addMaterial({ commit }, material) {
		const { data } = await api.call({ path: 'materials', data: material });
		commit('ADD_MATERIAL', data.data);

		return data.data;
	},

	async editMaterial({ commit }, { id, material }) {
		const { data } = await api.call({ path: `materials/${id}`, data: material, method: 'PUT' });
		commit('EDIT_MATERIAL', { id, data: data.data });

		return data.data;
	},

	async deleteMaterial({ commit }, id) {
		await api.call({ path: `materials/${id}`, method: 'DELETE' });
		commit('DELETE_MATERIAL', id);

		return id;
	},
};
