import api from '@/utils/api';

export const state = {
	workers: [],
};

export const getters = {};

export const mutations = {
	SET_WORKERS(state, workers) {
		state.workers = workers;
	},
};

export const actions = {
	async getWorkers({ commit }, projectId) {
		const { data } = await api.call({ path: `projects/${projectId}/able-to-work`, method: 'GET' });
		commit('SET_WORKERS', data);

		return data;
	},
};
