import api from '@/utils/api';
import Vue from 'vue';

export const state = {
	solutions: [],
};

export const getters = {};

export const mutations = {
	SET_SOLUTINS(state, solutions) {
		state.solutions = solutions;
	},

	ADD_SOLUTION(state, solution) {
		state.solutions.push(solution);
	},

	EDIT_SOLUTION(state, newData) {
		state.solutions.forEach((solution, i) => {
			if (solution.id === newData.id) {
				Vue.set(state.solutions, i, newData);
			}
		});
	},
};

export const actions = {
	async getSolutions({ commit, state }) {
		if (state.solutions.length) return state.solutions;

		const { data } = await api.call({ path: 'grm/solutions' });
		commit('SET_SOLUTINS', data.data);

		return data.data;
	},

	async addSolution({ commit }, solData) {
		const { data } = await api.call({ path: 'grm/solutions/store', data: solData });
		commit('ADD_SOLUTION', data.data);
		console.log('🚀 ~ file: solutions.js ~ line 41 ~ addSolution ~ data.data', data.data);

		return data.data;
	},

	async editSolution({ commit }, { id, solData }) {
		const { data } = await api.call({ path: `grm/solutions/${id}/update`, data: solData });
		commit('EDIT_SOLUTION', data.data);

		return data.data;
	},
};
