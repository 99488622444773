import axios from 'axios';
import store from '@/store';
import router from '@/router';

const baseURL = process.env.VUE_APP_URL ?? '';
let requestCount = 0;

const http = axios.create({
	baseURL: `${baseURL}/api/v2/`,
	withCredentials: true,
});

http.interceptors.request.use((config) => {
	// Do something before request is sent
	store.commit('HAS_ERROR', false);
	return config;
});

export function setToken(token) {
	http.defaults.headers.common.Authorization = token ? `Bearer ${token}` : '';
}

function onSuccess(response) {
	// eslint-disable-next-line no-plusplus
	requestCount--;
	if (requestCount === 0) store.commit('IS_LOADING', false);

	store.commit('HAS_ERROR', false);
	return response;
}

function onError(error) {
	// eslint-disable-next-line no-plusplus
	requestCount--;
	if (requestCount === 0) store.commit('IS_LOADING', false);

	store.commit('HAS_ERROR', true);

	if (error.response) {
		// in case the CSRF cookie is not set
		if (error.response.status === 419) {
			return http
				.request({
					method: 'get',
					url: `${baseURL}sanctum/csrf-cookie`,
				})
				.then(() => http.request(error.config));
		}

		if (error.response.status === 401) {
			if (router.currentRoute.name === 'login') {
				store.commit('SET_ERROR', {
					message: 'Sorry! You are not allowed to login!',
					error,
				});
			} else {
				router.push({ name: 'login', query: { redirectFrom: router.currentRoute } });
				store.commit('SET_ERROR', {
					message: 'Sorry! Your session have been expired!',
					error,
				});
			}
		} else {
			store.commit('SET_ERROR', {
				message: 'Something went wrong! please try again.',
				error,
			});
		}
	} else if (error.request) {
		// The request was made but no response was received
		store.commit('SET_ERROR', {
			message: 'We could not connect with our server. Please check your internet connection, and try again',
			error,
		});
	} else {
		store.commit('SET_ERROR', {
			message: 'Something went wrong! please try again.',
			error,
		});
	}
}

export default {
	async call({ path, data = {}, method = 'POST', noLoading = false, headers = {} }) {
		try {
			// eslint-disable-next-line no-plusplus
			requestCount++;
			store.commit('IS_LOADING', !noLoading);

			const res = await http({
				method,
				url: path,
				data,
				headers,
			});

			onSuccess(res);
			return res;
		} catch (error) {
			onError(error);
			return error;
		}
	},

	async getFile(url, config = {}) {
		try {
			const res = await http.get(url, { responseType: 'blob', ...config });

			onSuccess(res);
			return res;
		} catch (error) {
			onError(error);
			return error;
		}
	},
};
