import api from '@/utils/api';
import { buildQueryString } from '@/utils/helpers';

export const state = {
	tpm: [],
};

export const getters = {};

export const mutations = {
	SET_TPM(state, tpm) {
		state.tpm = tpm;
	},
};

export const actions = {
	async getTPM({ commit }, params) {
		const path = params ? `m&e/tpm?${buildQueryString(params)}` : 'm&e/tpm';
		const { data } = await api.call({ path, method: 'GET', noLoading: true });
		commit('SET_TPM', data.data);

		return data;
	},
};
