export default [
	{
		path: '',
		name: 'grm',
		component: () => import('@/views/grm/dashboard/Index.vue'),
		meta: {
			pageTitle: 'GRM',
			breadcrumb: [
				{
					text: 'GRM',
					active: true,
				},
			],
			authRequired: true,
		},
	},
	{
		path: 'complaints',
		name: 'grm.complaints',
		component: () => import('@/views/grm/complaints/Index.vue'),
		meta: {
			pageTitle: 'GRM',
			breadcrumb: [
				{
					text: 'GRM',
					to: '/grm',
					active: false,
				},
				{
					text: 'Complaints',
					active: true,
				},
			],
			authRequired: true,
		},
	},
	{
		path: 'complaints/:complaintId',
		name: 'grm.complaints.details',
		component: () => import('@/views/grm/complaints/details/Index.vue'),
		meta: {
			pageTitle: 'GRM',
			breadcrumb: [
				{
					text: 'GRM',
					to: '/grm',
					active: false,
				},
				{
					text: 'Complaints',
					to: '/grm/complaints',
					active: false,
				},
				{
					text: 'Details',
					active: true,
				},
			],
			authRequired: true,
		},
		props: true,
	},
	{
		path: 'complaints/:complaintId/report',
		name: 'grm.complaints.details.report',
		component: () => import('@/views/grm/complaints/Report.vue'),
		meta: {
			pageTitle: 'GRM',
			breadcrumb: [
				{
					text: 'GRM',
					to: '/grm',
					active: false,
				},
				{
					text: 'Complaints',
					to: '/grm/complaints',
					active: false,
				},
				{
					text: 'Report',
					active: true,
				},
			],
			authRequired: true,
		},
		props: true,
	},
	{
		path: 'reports',
		name: 'grm.reports',
		component: () => import('@/views/grm/reports/Index.vue'),
		meta: {
			pageTitle: 'GRM',
			breadcrumb: [
				{
					text: 'GRM',
					to: '/grm',
					active: false,
				},
				{
					text: 'Reports',
					active: true,
				},
			],
			authRequired: true,
		},
	},
	{
		path: 'settings',
		name: 'grm.settings',
		component: () => import('@/views/grm/settings/Index.vue'),
		meta: {
			pageTitle: 'GRM',
			breadcrumb: [
				{
					text: 'GRM',
					to: '/grm',
					active: false,
				},
				{
					text: 'Settings',
					active: true,
				},
			],
			authRequired: true,
		},
	},
];
