/* eslint-disable no-param-reassign */
import { getState } from '@/utils/helpers';

function commentNode(el, vnode) {
	const comment = document.createComment(' ');
	Object.defineProperty(comment, 'setAttribute', {
		value: () => undefined,
	});

	vnode.text = ' ';
	vnode.elm = comment;
	vnode.isComment = true;
	vnode.context = undefined;
	vnode.tag = undefined;
	vnode.data.directives = undefined;

	if (vnode.componentInstance) {
		vnode.componentInstance.$el = comment;
	}

	if (el.parentNode) {
		el.parentNode.replaceChild(comment, el);
	}
}

const plugin = {
	install(Vue) {
		// declare the main directive
		Vue.directive('can', (el, binding, vnode) => {
			const behaviour = binding.modifiers.disable ? 'disable' : 'hide';
			const permissions = getState('user')?.permissions.map((perm) => perm.name);

			const perm = binding.value;
			const allwoed = permissions.includes(perm);

			if (!allwoed) {
				if (behaviour === 'hide') {
					commentNode(el, vnode);
				} else {
					el.disabled = true;
				}
			}
		});
	},
};

export default plugin;
