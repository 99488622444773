import camelCase from 'lodash/camelCase';

const modulesCache = {};
const storeData = { modules: {} };

// eslint-disable-next-line wrap-iife
(function updateModules() {
	const allModules = require.context('.', true, /^((?!index|\.unit\.).)*\.js$/);

	allModules.keys().forEach((moduleName) => {
		const moduleObject = allModules(moduleName);

		if (modulesCache[moduleName] === moduleObject) return;

		// if it not on the cache cache it
		modulesCache[moduleName] = moduleObject;

		// Get the module path as an array.
		const modulePath = moduleName
			.replace(/^\.\//, '')
			.replace(/\.\w+$/, '')
			.split(/\//)
			.map(camelCase);

		// eslint-disable-next-line no-use-before-define
		const { modules } = getNamespace(storeData, modulePath);

		// add the definition of the last module in the path.
		modules[modulePath.pop()] = {
			namespaced: true,
			...moduleObject,
		};
	});

	// If the environment supports hot reloading...
	if (module.hot) {
		module.hot.accept(allModules.id, () => {
			updateModules();
			// eslint-disable-next-line global-require
			require('../index').default.hotUpdate({
				modules: storeData.modules,
			});
		});
	}
})();

// Recursively get the namespace of a Vuex module, even if nested.
function getNamespace(subtree, path) {
	if (path.length === 1) return subtree;

	const namespace = path.shift();

	// eslint-disable-next-line no-param-reassign
	subtree.modules[namespace] = {
		modules: {},
		namespaced: true,
		...subtree.modules[namespace],
	};

	return getNamespace(subtree.modules[namespace], path);
}

export default storeData.modules;
