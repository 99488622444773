import api from '@/utils/api';
import { buildQueryString } from '@/utils/helpers';

export const state = {
	items: [],
	subItems: [],
};

export const getters = {};

export const mutations = {
	SET_ITEMS(state, items) {
		state.items = items;
	},

	SET_SUB_ITEMS(state, subItems) {
		state.subItems = subItems;
	},
};

export const actions = {
	async getItems(_, params) {
		const path = `items?${buildQueryString(params)}`;

		const { data } = await api.call({ path, method: 'GET', noLoading: true });

		return data;
	},

	async getSubItems(_, params) {
		const path = `sub-items?${buildQueryString(params)}`;

		const { data } = await api.call({ path, method: 'GET', noLoading: true });

		return data;
	},

	async addItem(_, data) {
		await api.call({ path: '/items', data, noLoading: true });
	},

	async updateItem(_, data) {
		await api.call({ path: `/items/${data.itemId}`, data, method: 'PUT', noLoading: true });
	},

	async addSubItem(_, data) {
		await api.call({ path: '/sub-items', data, noLoading: true });
	},

	async updateSubItem(_, data) {
		await api.call({ path: `/sub-items/${data.itemId}`, data, method: 'PUT', noLoading: true });
	},
};
