import Vue from 'vue';
import api from '@/utils/api';
import { generateId, getOrder, getDefaultPage, buildQueryString } from '@/utils/helpers';
import { fieldFinder } from '@/utils/fieldFinder';

export const state = {
	forms: [],
	currentForm: null,
};

export const getters = {};

export const mutations = {
	SET_FORMS(state, forms) {
		state.forms = forms;
	},

	SET_CURRENT_FORM(state, form) {
		state.currentForm = form;
	},

	ADD_FORM(state, newForm) {
		state.forms.push(newForm);
	},

	EDIT_FORM(state, formData) {
		state.forms.forEach((form, i) => {
			if (form.id === formData.id) {
				Vue.set(state.forms, i, formData);
			}
		});
	},

	DELETE_FORM(state, formId) {
		state.forms = state.forms.filter((form) => form.id !== formId);
	},

	ADD_FORM_PAGE({ currentForm }, { pageData }) {
		currentForm.pages.push(pageData);
	},

	EDIT_FORM_PAGE({ currentForm }, { pageData }) {
		currentForm.pages.forEach((page, i) => {
			if (page.id === pageData.id) {
				Vue.set(currentForm.pages, i, pageData);
			}
		});
	},

	DELETE_FORM_PAGE({ currentForm }, { pageId }) {
		Vue.set(
			currentForm,
			'pages',
			// eslint-disable-next-line comma-dangle
			currentForm.pages.filter((page) => page.id !== pageId),
		);
	},

	ADD_PAGE_FIELD({ currentForm }, { fieldData, pageId }) {
		currentForm.pages.find((p) => p.id === pageId).fields.push(fieldData);
	},

	EDIT_FORM_FIELD({ currentForm }, { fieldData }) {
		fieldFinder(currentForm)(fieldData.id, fieldData.type, (fields, index) => {
			Vue.set(fields, index, fieldData);
		});
	},

	DELETE_FIELD({ currentForm }, { fieldId, fieldType, parentFieldId }) {
		fieldFinder(currentForm)(fieldId, fieldType, (fields, index, page) => {
			Vue.delete(fields, index);

			if (!(fields.length === 0 && parentFieldId)) return;

			const parentField = page.fields.find((f) => f.id === parentFieldId);
			if (parentField.type === 'ListField') {
				const option = parentField.options.find((o) => o.skipPages?.fields.pages[0].fields.length === 0);
				Vue.set(option, 'skipPages', null);
			}

			if (parentField.type === 'DynamicField') {
				Vue.delete(
					page.fields,
					// eslint-disable-next-line comma-dangle
					page.fields.findIndex((f) => f.id === parentFieldId),
				);
			}
		});
	},

	ADD_TO_DYNAMIC_FIELD({ currentForm }, { fieldId, fieldData }) {
		fieldFinder(currentForm)(fieldId, 'DynamicField', (fields, index) => {
			fields[index].form.pages[0].fields.push({ ...fieldData, order: getOrder(fields[index].form.pages[0].fields) });
		});
	},

	ADD_SKIP_TO_FIELD({ currentForm }, { fieldId, fieldData, optionId }) {
		fieldFinder(currentForm)(fieldId, 'ListField', (fields, index) => {
			const skipToOption = fields[index].options.find((o) => o.id === optionId);

			if (!skipToOption.skipPages) {
				Vue.set(skipToOption, 'skipPages', {
					fields: {
						id: generateId(),
						name: null,
						description: null,
						order: null,
						pages: [getDefaultPage()],
					},
				});
			}

			skipToOption.skipPages.fields.pages[0].fields.push({
				...fieldData,
				order: getOrder(skipToOption.skipPages.fields.pages[0].fields),
			});
		});
	},
};

export const actions = {
	async getForms({ commit }, params) {
		const path = params ? `forms?${buildQueryString(params)}` : 'forms';

		const { data } = await api.call({
			path,
			method: 'GET',
			noLoading: true,
		});
		commit('SET_FORMS', data.data);

		return data;
	},

	async getProjectForms(_, id) {
		const { data } = await api.call({ path: `forms/project/${id}`, method: 'GET' });

		return data.data;
	},

	async getForm({ commit }, { formId, noLoading }) {
		const { data } = await api.call({ path: `forms/${formId}/pages`, noLoading });
		const form = data.data.pages.length === 0 ? { ...data.data, pages: [getDefaultPage()] } : data.data;

		form.pages.sort((pageA, pageB) => pageA.order - pageB.order);
		form.pages.forEach((page) => page.fields.sort((fieldA, fieldB) => fieldA.order - fieldB.order));

		commit('SET_CURRENT_FORM', form);
		return form;
	},

	async addForm({ commit }, formData) {
		const { data } = await api.call({
			path: 'forms/store',
			data: {
				id: generateId(),
				...formData,
			},
		});

		commit('ADD_FORM', data.data);
		return data.data;
	},

	async editForm({ commit }, formData) {
		const { data } = await api.call({ path: `forms/${formData.id}/update`, data: formData });
		commit('EDIT_FORM', data.data);
		return data.data;
	},

	async deleteForm({ commit }, formId) {
		await api.call({ path: `forms/${formId}/delete` });
		commit('DELETE_FORM', formId);
	},

	addPage({ state, commit }, { pageData }) {
		const data = {
			id: generateId(),
			order: getOrder(state.currentForm.pages),
			...pageData,
			fields: [],
		};

		return new Promise((resolve) => {
			commit('ADD_FORM_PAGE', { pageData: data });
			resolve(data);
		});
	},

	editPage({ commit }, data) {
		return new Promise((resolve) => {
			commit('EDIT_FORM_PAGE', data);
			resolve(data);
		});
	},

	deletePage({ commit }, { pageId }) {
		return new Promise((resolve) => {
			commit('DELETE_FORM_PAGE', { pageId });
			resolve(true);
		});
	},

	addField({ state, commit }, { pageId, fieldData }) {
		return new Promise((resolve, reject) => {
			const page = state.currentForm.pages.find((p) => p.id === pageId);
			if (!page) return reject(new Error('Page not found'));

			const data = {
				id: generateId(),
				order: getOrder(page.fields),
				...fieldData,
				value: null,
			};

			commit('ADD_PAGE_FIELD', { fieldData: data, pageId });
			resolve(data);
		});
	},

	addToDynamicField({ commit }, { fieldId, fieldData }) {
		return new Promise(() => {
			commit('ADD_TO_DYNAMIC_FIELD', {
				fieldData: {
					id: generateId(),
					...fieldData,
					value: null,
				},
				fieldId,
			});
		});
	},

	addSkipToField({ commit }, { fieldId, fieldData, optionId }) {
		return new Promise(() => {
			commit('ADD_SKIP_TO_FIELD', {
				fieldData: {
					id: generateId(),
					...fieldData,
					value: null,
				},
				fieldId,
				optionId,
			});
		});
	},

	editField({ commit }, { fieldData }) {
		return new Promise(() => {
			commit('EDIT_FORM_FIELD', { fieldData });
		});
	},

	deleteField({ commit }, { fieldId, fieldType, parentFieldId }) {
		return new Promise(() => {
			commit('DELETE_FIELD', { fieldId, fieldType, parentFieldId });
		});
	},

	async save({ commit, state }) {
		// remove empty dynamic fields
		const formPages = state.currentForm.pages.map((page) => {
			const pageInfo = page;

			pageInfo.fields = page.fields.filter((field) => {
				if (field.type !== 'DynamicField') return true;

				return field.form.pages[0].fields.length > 0;
			});

			return pageInfo;
		});

		const { data } = await api.call({ path: `forms/${state.currentForm.id}/pages/sync`, data: { pages: formPages } });

		data.data.pages.sort((pageA, pageB) => pageA.order - pageB.order);
		data.data.pages.forEach((page) => page.fields.sort((fieldA, fieldB) => fieldA.order - fieldB.order));

		commit('SET_CURRENT_FORM', data.data);
		return data.data;
	},
};
