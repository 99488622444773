import api from '@/utils/api';

export const state = {
	workers: [],
	workersReports: [],
	buyingReports: []
};

export const mutations = {
	SET_WORKERS(state, workers) {
		state.workers = workers;
	},

	SET_WORKERS_REPORTS(state, reports) {
		state.workersReports = reports;
	},

	SET_PAYING_REPORTS(state, reports) {
		state.buyingReports = reports;
	}
};

export const actions = {
	async getWorkers({ commit }, id) {
		const { data: workers } = await api.call({ path: `beneficiaries/${id}`, method: 'GET' });
		commit('SET_WORKERS', workers.data);

		return workers.data;
	},

	async getDailyReports(_, { projectId, id, date }) {
		const query = new URLSearchParams({ id: id ?? '', date: date ?? '' });
		const { data } = await api.call({ path: `beneficiaries/daily-report/${projectId}?${query}`, method: 'GET', noLoading: true });

		return data.data;
	},

	async getPayingReports(_, { projectId, id, date }) {
		const query = new URLSearchParams({ id: id ?? '', date: date ?? '' });
		const { data } = await api.call({ path: `beneficiaries/paying-report/${projectId}?${query}`, method: 'GET', noLoading: true });

		return data.data;
	},

	async getMembers(_, { id, fromDate, toDate }) {
		const { data } = await api.call({
			path: `beneficiaries/${id}?from_date=${fromDate}&to_date=${toDate}`,
			method: 'GET',
			noLoading: true
		});
		return data.data;
	},

	async addWorker(_, data) {
		await api.call({ path: 'beneficiaries/store', data });
	}
};
