import api from '@/utils/api';
import { buildQueryString, getState, saveState } from '@/utils/helpers';

function calcProgress(item, reports) {
	const done = reports.reduce((prev, cur) => prev + parseFloat(cur.executed_quantity), 0);
	const val = ((done / item.quantity) * 100).toFixed(1);
	return !isNaN(val) ? val : 0;
}

export const state = {
	projects: [],

	currentProject: getState('currentProject') || undefined,
};

export const getters = {
	projectsAsOptions(state) {
		return state.projects.map((p) => ({
			label: p.id,
			id: p.id,
		}));
	},
};

export const mutations = {
	SET_PROJECTS(state, projects) {
		state.projects = projects;
	},

	SET_CURRENT_PROJECT(state, project) {
		state.currentProject = project;
		saveState('currentProject', project);
	},
};

export const actions = {
	async getProjects(_, params) {
		let path = params ? `projects?${buildQueryString(params)}` : 'projects';
		if (params.type && params.currentPage) {
			path += `&type=${params.type}`;
		}

		const { data } = await api.call({
			path,
			method: 'GET',
			noLoading: true,
		});

		return data;
	},

	async getProjectShortList() {
		const { data } = await api.call({
			path: 'projects/short-list',
			method: 'GET',
		});

		return data;
	},

	async getProjectDetails(_, projectId) {
		const { data } = await api.call({ path: `projects/${projectId}/details` });
		return data.data;
	},

	async getProjectStores(_, projectId) {
		const { data } = await api.call({ path: `projects/${projectId}/stores`, method: 'GET', noLoading: true });
		return data.data;
	},

	async syncProject(_, projectId) {
		const { data } = await api.call({ path: `projects/${projectId}/sync` });
		return data.data;
	},

	async syncProjects(_, projects) {
		const { data } = await api.call({ path: 'projects/sync', data: { projects } });
		return data.data;
	},

	async saveItems(_, { items, id }) {
		const { data } = await api.call({ path: `projects/${id}/sub-projects/items`, data: { items } });
		return data.data;
	},

	async getProject({ state, commit }, projectId) {
		if (state.currentProject && state.currentProject.id === projectId) return state.currentProject;

		const { data } = await api.call({ path: `projects/${projectId}`, method: 'GET' });
		commit('SET_CURRENT_PROJECT', data.data);
		return data.data;
	},

	async getProjectItems({ state, dispatch, rootGetters }, projectId) {
		let { items } = state.currentProject;
		await dispatch('items/getItemsReports', projectId, { root: true });
		items = items.map((item) => {
			const reports = rootGetters['items/itemReports'](item.id);
			return {
				...item,
				progress: calcProgress(item, reports),
			};
		});

		return items;
	},

	getFormSubmissions(_, { projectId, formId, options }) {
		let path = `projects/${projectId}/forms/${formId}/submissions`;
		path = options?.perPage ? `${path}?${buildQueryString(options)}` : path;

		return api.call({ path, noLoading: true });
	},

	getFormSubmission(_, { projectId, formId, submissionId, noLoading }) {
		return api.call({ path: `projects/${projectId}/forms/${formId}/submission/${submissionId}`, noLoading: noLoading ?? false });
	},

	getVisitsReports(_, projectId) {
		return api.call({ path: `projects/${projectId}/forms/38/submissions/data`, noLoading: true });
	},

	getMachinesReports(_, projectId) {
		return api.call({ path: `projects/${projectId}/forms/37/submissions/data`, noLoading: true });
	},

	getMaterialsReports(_, projectId) {
		return api.call({ path: `projects/${projectId}/forms/36/submissions/data`, noLoading: true });
	},

	getReportsOverview(_, { projectId, startDate, endDate }) {
		return api.call({
			path: `projects/${projectId}/reports-overview?startDate=${startDate}&endDate=${endDate}`,
			method: 'GET',
			noLoading: true,
		});
	},
};
