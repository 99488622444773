import api from '@/utils/api';

export const state = {};

export const getters = {};

export const mutations = {};

export const actions = {
	async getSubProjects(_, projectId) {
		const { data } = await api.call({ path: `projects/${projectId}/sub-projects`, method: 'GET', noLoading: true });
		return data.data;
	},

	async addSubProject(_, { projectId, data }) {
		const res = await api.call({ path: `projects/${projectId}/sub-projects`, data, noLoading: true });
		return res.data.data;
	},

	async updateSubProject(_, { id, data }) {
		const res = await api.call({ path: `sub-projects/${id}`, method: 'PUT', data, noLoading: true });
		return res.data.data;
	},

	async addSubProjectItem(_, { subProjectId, data }) {
		const res = await api.call({ path: `sub-projects/${subProjectId}/items`, data, noLoading: true });
		return res.data.data;
	},

	async updateSubProjectItem(_, { subProjectId, data }) {
		const res = await api.call({
			path: `sub-projects/${subProjectId}/items/${data.id}`,
			method: 'PUT',
			data,
			noLoading: true,
		});

		return res.data.data;
	},

	async deleteItem(_, { subProjectId, itemId }) {
		await api.call({ path: `sub-projects/${subProjectId}/items/${itemId}`, method: 'DELETE' });
	},
};
