<template>
	<b-overlay :show="isLoading" variant="secondary">
		<template #overlay>
			<div id="loading-bg">
				<div class="loading">
					<div class="effect-1 effects"></div>
					<div class="effect-2 effects"></div>
					<div class="effect-3 effects"></div>
				</div>
			</div>
		</template>
		<div id="app" class="h-100" :class="[skinClasses]">
			<layout-horizontal />
		</div>
	</b-overlay>
</template>

<script>
import { BOverlay } from 'bootstrap-vue';
import { mapState } from 'vuex';
import { $themeColors, $themeBreakpoints, $themeConfig } from '@themeConfig';
import { watch } from '@vue/composition-api';

import store from '@/store';
import useAppConfig from '@core/app-config/useAppConfig';
import { useWindowSize, useCssVar } from '@vueuse/core';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

const LayoutHorizontal = () => import('@/layouts/horizontal/LayoutHorizontal.vue');

export default {
	components: {
		LayoutHorizontal,
		BOverlay,
		// eslint-disable-next-line vue/no-unused-components
		ToastificationContent
	},

	computed: {
		layout() {
			if (this.$route.meta.layout === 'full') return 'layout-full';
			return `layout-${this.contentLayoutType}`;
		},
		contentLayoutType() {
			return this.$store.state.appConfig.layout.type;
		},
		...mapState(['isLoading', 'hasError', 'error'])
	},

	watch: {
		isLoading(val) {
			if (val) {
				document.body.classList.add('body-loading');
			} else {
				document.body.classList.remove('body-loading');
			}
		},

		hasError(val) {
			if (val) this.showToast();
		}
	},

	beforeCreate() {
		// Set colors in theme
		const colors = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'light', 'dark'];

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = colors.length; i < len; i++) {
			$themeColors[colors[i]] = useCssVar(`--${colors[i]}`, document.documentElement).value.trim();
		}

		// Set Theme Breakpoints
		const breakpoints = ['xs', 'sm', 'md', 'lg', 'xl'];

		// eslint-disable-next-line no-plusplus
		for (let i = 0, len = breakpoints.length; i < len; i++) {
			$themeBreakpoints[breakpoints[i]] = Number(
				useCssVar(`--breakpoint-${breakpoints[i]}`, document.documentElement).value.slice(0, -2)
			);
		}

		// Set RTL
		const { isRTL } = $themeConfig.layout;
		document.documentElement.setAttribute('dir', isRTL ? 'rtl' : 'ltr');
	},

	setup() {
		const { skin, skinClasses } = useAppConfig();

		if (skin.value === 'dark') document.body.classList.add('dark-layout');

		store.commit('app/UPDATE_WINDOW_WIDTH', window.innerWidth);
		const { width: windowWidth } = useWindowSize();
		watch(windowWidth, (val) => {
			store.commit('app/UPDATE_WINDOW_WIDTH', val);
		});

		return {
			skinClasses
		};
	},

	methods: {
		showToast() {
			this.$toast({
				component: ToastificationContent,
				props: {
					title: 'We have a problem',
					icon: 'AlertCircleIcon',
					text: this.error.message,
					variant: 'danger'
				}
			});
		}
	}
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__open-indicator {
	transform: scale(0.7) !important;
	margin: 0.1rem;
}

.vs--open .vs__open-indicator {
	transform: rotate(180deg) scale(0.7) !important;
}

.vs__open-indicator {
	fill: rgba(60, 60, 60, 0.5) !important;
}

.dark-layout .vs__actions svg {
	fill: #404656 !important;
}

.card .border-secondary {
	border-color: #e2e1e7 !important;
}

.b-form-datepicker .btn {
	padding: 0.6rem 1rem;
}

.b-overlay {
	z-index: 1000 !important;
	min-height: 100vh;
}

.body-loading {
	overflow-y: hidden;
}

.text-ellipsis {
	width: 280px;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
	direction: rtl;
}
</style>
