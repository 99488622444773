import api from '@/utils/api';

export const state = {
	locations: [],
};

export const getters = {};

export const mutations = {
	SET_LOCATIONS(state, locations) {
		state.locations = locations;
	},
};

export const actions = {
	async getLocations({ commit }) {
		const { data } = await api.call({ path: 'projects/locations', method: 'GET' });
		commit('SET_LOCATIONS', data);

		return data;
	},
};
