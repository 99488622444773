import Vue from 'vue';
import Vuex from 'vuex';

// Modules
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import modules from './modules/index';

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		isLoading: false,
		hasError: false,
		error: null,
	},

	mutations: {
		IS_LOADING(state, val) {
			state.isLoading = val;
		},
		HAS_ERROR(state, val) {
			state.hasError = val;
		},
		SET_ERROR(state, val) {
			state.error = val;
		},
	},

	modules: {
		app,
		appConfig,
		verticalMenu,
		...modules,
	},
	strict: process.env.DEV,
});
