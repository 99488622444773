export default [
	{
		path: '',
		name: 'project.details',
		component: () => import('@/views/projects/details/ProjectDetails.vue'),
		meta: {
			pageTitle: 'Projects',
			breadcrumb: [
				{
					to: '/projects',
					text: 'Projects',
					active: false
				},
				{
					text: 'Details',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'workers',
		name: 'project.details.workers',
		component: () => import('@/views/projects/details/workers/Index.vue'),
		meta: {
			pageTitle: 'Workers',
			breadcrumb: [
				{
					to: '/projects',
					text: 'Projects',
					active: false
				},
				{
					text: 'Workers',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'items',
		name: 'project.details.items',
		component: () => import('@/views/projects/details/items/Index.vue'),
		meta: {
			pageTitle: 'Items',
			breadcrumb: [
				{
					to: '/projects',
					text: 'Projects',
					active: false
				},
				{
					text: 'Items',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'reports',
		name: 'project.details.reports',
		component: () => import('@/views/projects/details/reports/Index.vue'),
		meta: {
			pageTitle: 'Reports',
			breadcrumb: [
				{
					to: '/projects',
					text: 'Projects',
					active: false
				},
				{
					text: 'Reports',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'forms',
		name: 'project.details.forms',
		component: () => import('@/views/projects/details/forms/Index.vue'),
		meta: {
			pageTitle: 'Forms',
			breadcrumb: [
				{
					to: '/projects',
					text: 'Projects',
					active: false
				},
				{
					text: 'Forms',
					active: true
				}
			],
			authRequired: true
		}
	},
	{
		path: 'notifications',
		name: 'project.details.notifications',
		component: () => import('@/views/Notifications.vue'),
		meta: {
			pageTitle: 'Notifications',
			breadcrumb: [
				{
					to: '/projects',
					text: 'Projects',
					active: false
				},
				{
					text: 'Notifications',
					active: true
				}
			],
			authRequired: true
		}
	}
];
