import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';
import { getState } from '@/utils/helpers';
import cfwRoutes from './cfw-routes';
import projectRoutes from './project-routes';
import mAndERoutes from './m&e-routes';
import grmRoutes from './grm-routes';
import settingsRoutes from './settings-routes';
import safeguardReports from './safeguard-reports';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior() {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: '/',
			name: 'home',
			component: () => import('@/views/Home.vue'),
			meta: {
				pageTitle: 'Home',
				breadcrumb: [
					{
						text: 'Home',
						active: true,
					},
				],
				authRequired: true,
			},
		},

		/* -- user profile --*/
		{
			path: '/profile',
			name: 'user-profile',
			component: () => import('@/views/profile/Index.vue'),
			meta: {
				pageTitle: 'Your profile',
				breadcrumb: [{ text: 'Your profile', active: true }],
				authRequired: true,
			},
		},

		{
			path: '/notifications',
			name: 'notifications',
			component: () => import('@/views/Notifications.vue'),
			meta: {
				pageTitle: 'Notifications',
				breadcrumb: [
					{
						text: 'Notifications',
						active: true,
					},
				],
				authRequired: true,
			},
		},

		/* -- General Projects --*/

		{
			path: '/projects',
			name: 'projects',
			component: () => import('@/views/projects/Index.vue'),
			meta: {
				pageTitle: 'Projects',
				breadcrumb: [
					{
						text: 'Projects',
						active: true,
					},
				],
				authRequired: true,
				group: 'projects',
			},
		},
		{
			path: '/projects/:id',
			name: 'project.details',
			component: () => import('@/views/projects/details/Index.vue'),
			meta: {
				pageTitle: 'Projects',
				breadcrumb: [
					{
						to: '/projects',
						text: 'Projects',
						active: false,
					},
					{
						text: 'Details',
						active: true,
					},
				],
				authRequired: true,
				group: 'projects',
				navActiveLink: 'projects',
			},
			props: true,
			children: projectRoutes,
		},

		/* -- Cash for work Projects --*/
		...cfwRoutes,

		/* -- Form Builder --*/
		{
			path: '/forms',
			name: 'forms',
			component: () => import('@/views/forms/Index.vue'),
			meta: {
				pageTitle: 'Forms',
				breadcrumb: [
					{
						text: 'Forms',
						active: true,
					},
				],
				authRequired: true,
				group: 'form_builder',
			},
		},
		{
			path: '/forms/:id',
			name: 'form-details',
			component: () => import('@/views/forms/details/FormDetails.vue'),
			meta: {
				pageTitle: 'Forms',
				breadcrumb: [
					{
						to: '/forms',
						text: 'Forms',
						active: false,
					},
					{
						text: 'Details',
						active: true,
					},
				],
				authRequired: true,
				group: 'form_builder',
				navActiveLink: 'forms',
			},
			props: true,
		},

		/* -- M&E --*/
		{
			path: '/m&e',
			name: 'm&e',
			component: () => import('@/views/m&e/Index.vue'),
			meta: {
				pageTitle: 'M&E',
				breadcrumb: [
					{
						text: 'M&E',
						active: true,
					},
				],
				authRequired: true,
			},
			children: mAndERoutes,
		},

		/* -- GRM --*/
		{
			path: '/grm',
			name: 'grm',
			component: () => import('@/views/grm/Index.vue'),
			meta: {
				pageTitle: 'GRM',
				breadcrumb: [
					{
						text: 'GRM',
						active: true,
					},
				],
				authRequired: true,
			},
			children: grmRoutes,
		},

		/* -- Migration --*/
		{
			path: '/migration',
			name: 'migration',
			component: () => import('@/views/migration/Index.vue'),
			meta: {
				pageTitle: 'Data Migration',
				breadcrumb: [
					{
						text: 'Migration',
						active: true,
					},
				],
				authRequired: true,
			},
		},

		/* -- Reports --*/
		{
			path: '/reports',
			name: 'reports',
			component: () => import('@/views/reports/Index.vue'),
			meta: {
				pageTitle: 'Reports',
				breadcrumb: [
					{
						text: 'Reports',
						active: true,
					},
				],
				authRequired: true,
			},
			children: [
				/* Safeguard reports */
				...safeguardReports,

				{
					path: 'submissions',
					name: 'submissions',
					component: () => import('@/views/reports/submissions/Index.vue'),
					meta: {
						pageTitle: 'Submissions',
						breadcrumb: [
							{
								text: 'Submissions',
								active: true,
							},
						],
						authRequired: true,
					},
				},
				{
					path: 'progress',
					name: 'progress',
					component: () => import('@/views/reports/progress/Index.vue'),
					meta: {
						pageTitle: 'Progress',
						breadcrumb: [
							{
								text: 'Progress',
								active: true,
							},
						],
						authRequired: true,
					},
				},
				{
					path: 'project-list',
					name: 'project-list',
					component: () => import('@/views/reports/project-list/Index.vue'),
					meta: {
						pageTitle: 'Project List',
						breadcrumb: [
							{
								text: 'Project List',
								active: true,
							},
						],
						authRequired: true,
					},
				},
				{
					path: 'project-forms',
					name: 'project-forms',
					component: () => import('@/views/reports/project-forms/Index.vue'),
					meta: {
						pageTitle: 'Project Forms',
						breadcrumb: [
							{
								text: 'Project Forms',
								active: true,
							},
						],
						authRequired: true,
					},
				},
				{
					path: 'projects-insight',
					name: 'projects-insight',
					component: () => import('@/views/reports/projects-insight/Index.vue'),
					meta: {
						pageTitle: 'Projects insight',
						breadcrumb: [
							{
								text: 'Projects insight',
								active: true,
							},
						],
						authRequired: true,
					},
				},

				{
					path: 'projects-insight/details',
					name: 'projects-insight-details',
					component: () => import('@/views/reports/projects-insight/Details.vue'),
					meta: {
						pageTitle: 'Projects insight details',
						breadcrumb: [
							{
								text: 'Projects insight',
								active: false,
							},
							{
								text: 'Projects insight details',
								active: true,
							},
						],
						authRequired: true,
					},
				},

				{
					path: 'baseline',
					name: 'baseline',
					component: () => import('@/views/reports/baseline/Index.vue'),
					meta: {
						pageTitle: 'Baseline reports',
						breadcrumb: [
							{
								text: 'Baseline reports',
								active: true,
							},
						],
						authRequired: true,
					},
				},

				{
					path: 'beneficiary-insight',
					name: 'beneficiary-insight',
					component: () => import('@/views/reports/beneficiary-insight/Index.vue'),
					meta: {
						pageTitle: 'Beneficiary insight',
						breadcrumb: [
							{
								text: 'Beneficiary insight',
								active: true,
							},
						],
						authRequired: true,
					},
				},

				{
					path: 'beneficiary-paying-insight',
					name: 'beneficiary-paying-insight',
					component: () => import('@/views/reports/beneficiary-paying-insight/Index.vue'),
					meta: {
						pageTitle: 'Beneficiary paying insight',
						breadcrumb: [
							{
								text: 'Beneficiary paying insight',
								active: true,
							},
						],
						authRequired: true,
					},
				},
			],
		},
		{
			path: '/reports-builder',
			name: 'reports-builder',
			component: () => import('@/views/reports/Builder.vue'),
			meta: {
				pageTitle: 'Builder',
				breadcrumb: [
					{
						text: 'Builder',
						active: true,
					},
				],
				authRequired: true,
			},
		},
		{
			path: '/settings',
			name: 'settings',
			component: () => import('@/views/settings/Index.vue'),
			meta: {
				pageTitle: 'Settings',
				breadcrumb: [
					{
						text: 'Settings',
						active: true,
					},
				],
				authRequired: true,
			},
			children: settingsRoutes,
		},
		{
			path: '/workers-cards',
			name: 'workers.cards',
			component: () => import('@/views/projects/details/workers/Cards.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/login',
			name: 'login',
			component: () => import('@/views/Login.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/404',
			name: '404',
			component: () => import('@/views/error/Error404.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '/access-denied',
			name: 'access-denied',
			component: () => import('@/views/error/AccessDenied.vue'),
			meta: {
				layout: 'full',
			},
		},
		{
			path: '*',
			redirect: '404',
		},
	],
});

function checkForAccess(to, group, page) {
	const user = getState('user');
	if (to.name === 'users' && user?.type !== 4) return false;

	const permissions = getState('user')?.permissions.map((perm) => perm.name) || [];

	if (to.meta.group && to.meta.group === group) {
		if (!permissions.includes(`${page}.view`)) return false;
	}

	return true;
}

router.beforeEach((to, from, next) => {
	function redirectToLogin() {
		next({ name: 'login', query: { redirectFrom: to.fullPath } });
	}

	function redirectToAccessDenied() {
		next({ name: 'access-denied', query: { redirectFrom: to.fullPath } });
	}

	const authRequired = to.matched.some((route) => route.meta.authRequired);
	if (authRequired && !store.getters['auth/isLoggedIn']) return redirectToLogin();

	if (!checkForAccess(to, 'form_builder', 'formsBuilder')) redirectToAccessDenied();
	if (!checkForAccess(to, 'projects', 'project')) redirectToAccessDenied();
	if (!checkForAccess(to, 'users', 'users')) redirectToAccessDenied();

	next();
});

router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg');
	if (appLoading) {
		appLoading.style.display = 'none';
	}
});

export default router;
