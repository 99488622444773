import api from '@/utils/api';

export const state = {
	batches: [],
};

export const gettters = {};

export const mutations = {
	SET_BATCHES(state, batches) {
		state.batches = batches;
	},

	SET_BATCHE(state, batche) {
		state.batches.push(batche);
	}
};

export const actions = {
	async getBatches({ commit }, subProjectId) {
		const { data } = await api.call({ path: `sub-projects/${subProjectId}/batches`, method: 'GET' });
		commit('SET_BATCHES', data.data);

		return data.data;
	}
};
