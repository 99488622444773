import api from '@/utils/api';

export const state = {
	indicators: []
};

export const getters = {
	getIndicator(state) {
		return (id) => state.indicators.find((i) => i.id === id);
	}
};

export const mutations = {
	SET_INDICATORS(state, indicators) {
		state.indicators = indicators;
	},

	ADD_INDICATOR(state, indicator) {
		state.indicators.push(indicator);
	}
};

export const actions = {
	async getIndicators({ commit }) {
		const { data } = await api.call({ path: '/indicators', method: 'GET' });

		commit('SET_INDICATORS', data.data);
		return data.data;
	},

	async getIndicator(_, id) {
		const { data } = await api.call({ path: `/indicators/${id}`, method: 'GET' });
		return data.data;
	},

	async addIndicator({ commit }, indicator) {
		const { data } = await api.call({ path: '/indicators', method: 'POST', data: indicator });

		commit('ADD_INDICATOR', data.data);
		return data.data;
	},

	async editIndicator(_, { indicator, id }) {
		const { data } = await api.call({ path: `/indicators/${id}`, method: 'PUT', data: indicator });
		return data.data;
	}
};
