import api from '@/utils/api';

export const state = {
	employees: [],
	employeesReports: [],
	buyingReports: [],
};

export const mutations = {
	SET_EMPLOYEES(state, employees) {
		state.employees = employees;
	},
};

export const actions = {
	async getEmployees({ commit }, id) {
		const { data } = await api.call({ path: `employees/${id}`, method: 'GET' });
		commit('SET_EMPLOYEES', data.data);

		return data.data;
	},

	async getDailyReports(_, { projectId, id, date }) {
		console.log(id);
		const query = new URLSearchParams({ id: id ?? '', date: date ?? '' });
		const { data } = await api.call({ path: `employees/daily-report/${projectId}?${query}`, method: 'GET', noLoading: true });

		return data.data;
	},

	async getPayingReports(_, { projectId, id, date }) {
		const query = new URLSearchParams({ id: id ?? '', date: date ?? '' });
		const { data } = await api.call({ path: `employees/paying-report/${projectId}?${query}`, method: 'GET', noLoading: true });

		return data.data;
	},
};
