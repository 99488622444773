import api, { setToken } from '@/utils/api';
import { saveState, getState } from '@/utils/helpers';

export const state = {
	user: getState('user') || undefined,
};

export const getters = {
	isLoggedIn(state) {
		return !!state.user;
	},
};

export const mutations = {
	SET_USER(state, userData) {
		state.user = userData;
		saveState('user', userData);
	},

	CLEAR_USER(state) {
		state.user = undefined;
		localStorage.removeItem('user');
		localStorage.removeItem('attributes');
	},
};

export const actions = {
	async init({ state, dispatch }) {
		setToken(state.user?.token);

		if (!state.user) {
			dispatch('logout');
		}
	},

	async login({ commit, dispatch }, loginData) {
		const { data } = await api.call({ path: 'auth/login', data: loginData });
		const userData = data.data;

		setToken(data.data.token);

		const permissions = await dispatch('users/getUserPermissions', userData.id, { root: true });
		userData.permissions = permissions;

		commit('SET_USER', userData);
		commit('attributes/SET_ATTRIBUTES', data.data.attributes, { root: true });

		return data;
	},

	async logout({ commit }) {
		await api.call({ path: 'auth/logout', method: 'GET' });
		commit('CLEAR_USER');
	},

	async changePassword({ commit }, passwordData) {
		await api.call({ path: 'auth/changePassword', method: 'POST', data: passwordData });
	},
};
