function findInTopLevelFields(page, fieldId, fieldType) {
	const fieldIndex = page.fields.findIndex((f) => f.id === fieldId && f.type === fieldType);
	return { fieldIndex, fieldsArray: page.fields };
}

function findInDynamicSubfields(page, fieldId, fieldType) {
	let fieldIndex = -1;
	let fieldsArray;

	const dynamicFields = page.fields.filter((f) => f.type === 'DynamicField');
	dynamicFields.every(({ form }) => {
		fieldIndex = form.pages[0].fields.findIndex((f) => f.id === fieldId && f.type === fieldType);
		if (fieldIndex > -1) fieldsArray = form.pages[0].fields;
		return fieldIndex === -1;
	});

	return { fieldIndex, fieldsArray };
}

function getListFieldsWithSkip(page) {
	const ListFields = page.fields.filter((f) => f.type === 'ListField');
	return ListFields.filter((f) => f.options.some((o) => !!o.skipPages));
}

function findInSkipFields(page, fieldId, fieldType) {
	let fieldIndex = -1;
	let fieldsArray;

	const withSkip = getListFieldsWithSkip(page);

	withSkip.every(({ options }) => {
		const optionsWithSkip = options.filter((o) => !!o.skipPages);

		optionsWithSkip.every(({ skipPages }) => {
			fieldIndex = skipPages.fields.pages[0].fields.findIndex((f) => f.id === fieldId && f.type === fieldType);
			if (fieldIndex > -1) fieldsArray = skipPages.fields.pages[0].fields;
			return fieldIndex === -1;
		});

		return fieldIndex === -1;
	});

	return { fieldIndex, fieldsArray };
}

const finders = [findInTopLevelFields, findInDynamicSubfields, findInSkipFields];

function findInPage(page, fieldId, fieldType, cb) {
	return !finders.every((finder) => {
		const { fieldIndex, fieldsArray } = finder(page, fieldId, fieldType);
		if (fieldIndex > -1) {
			cb(fieldsArray, fieldIndex, page);
		}

		return fieldIndex === -1;
	});
}

function fieldFinder(form) {
	return (fieldId, fieldType, cb) => {
		form.pages.every((page) => {
			const found = findInPage(page, fieldId, fieldType, cb);
			return !found;
		});
	};
}

export { fieldFinder };
